// import { getWechatConfig } from "@/api/login";  //为你提供timestamp、nonceStr、signature的后端接口

import wx from "weixin-js-sdk"

// const APPID = "wxa7610ec35a47601c"; //公众号的appId（注意不是小程序的appid，我太菜了，我就用错了）

/**
 * 获取微信配置
 * @param {*} tag 调用页面的this
 * @param {*} share_title 分享标题
 * @param {*} share_desc 分享描述
 * @param {*} share_link 分享链接
 * @param {*} share_cover 分享封面（配图）
 * @returns 
 */
export const wechatConfig = (share_title, share_desc, share_link, share_cover) => {
  const cover = share_cover || 'https://cdn.teamax-cn.cn/static/Images/pay/share-img.jpg'; //不重要的默认图片地址
  return new Promise((resolve, reject) => {
    wx.ready(function () { //通过ready接口处理成功验证
      wx.checkJsApi({
        jsApiList: ["updateAppMessageShareData", "updateTimelineShareData", 'checkJsApi', 'scanQRCode'],
        success (res) {
          console.log('aaaa', res);
        }
      });
      console.log("js-sdk配置成功！");
      
      //分享给朋友
      wx.updateAppMessageShareData({
        title: share_title || "默认标题", // 分享标题
        desc: share_desc || "默认描述", // 分享描述
        link: location.origin+`/${share_link||'index'}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: cover, // 分享后显示的封面图
        success: function () {
          console.info("分享给朋友");
        }, // 设置成功回调
      });
      
      //分享到朋友圈
      wx.updateTimelineShareData({
        title: share_title || "默认标题", // 分享标题
        link: location.origin+`/${share_link||'index'}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: cover, // 分享图标
        success: function () {
          // 用户点击了分享后执行的回调函数
          console.info("分享到朋友圈");
        }
      })
      return resolve(true)
    });
    wx.error(function (res) {
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      console.info("config信息验证失败");
      console.info(res);
      return reject(false)
    });
  })
}
