
<template>
  <!--分享-->
  <div id="myift1" style="width: 100%; height: 100%; background: rgba(0,0,0,0.8); position: fixed; left: 0; top: 0; z-index: 9999;" @click="hideShare">
    <div style="width: 90%; margin: 5% auto; position: relative;">
        <img src="https://cdn.teamax-cn.cn/static/Images/Group/share.png" alt="" style="width: 100%;" />
        <span id="mycel1" style="position: absolute; left: 0; top: 0; width: 24px; height: 25px; display: block;">
            <img src="https://cdn.teamax-cn.cn/static/Content/ZPrize/images/xx.png" alt="" />
        </span>
        <div style="color:#fff; text-align:center; font-size:15px; line-height:2;">
            <h4>邀请<b style="color:#dce03c; font-size:18px;"> 法人({{legalName}}) </b>进行人脸识别验证身份信息！</h4>
            <p>点击右上角按钮，发送给法人，进行人脸核身操作</p>
        </div>
    </div>
  </div>  
</template>

<script>
export default {
  props: {
    legalName: null,    
  },
  methods: {
    hideShare(){
      this.$emit("close")
    }
  }
}
</script>

<style lang='less' scoped>
  
</style>
