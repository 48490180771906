
import request from "@/utils/request";


//查询商户个人中心收款笔数和金额
export function getMerInfo() {
  return request({
    url: '/wxapp/mer/info',
    method: 'get'
  })
}

// 根据订单号获取订单信息
export function getScanResult(barcode) {
  return request({
    url: `/wxapp/mer/queryPayLog?sysOrderNo=` + barcode,
    method: 'get'
  })
}

// 确认退款
export function confirmRefund(data) {
  return request({
    url: `/wxapp/mer/scanRefund`,
    method: 'post',
    data
  })
}